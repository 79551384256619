import styled from "styled-components/macro";

export const Title = styled.h2`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  /* identical to box height */

  color: #0e1e57;

  margin: 0;

  text-align: center;

  @media (min-width: 1200px) {
    text-align: left;
  }
`;
