import styled from "styled-components/macro";

export const BaseButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  text-decoration: none;
`;
