export const Dropdown = ({
  options,
  ...props
}: {
  options: Array<{
    title: string;
    value: string;
  }>;
  [key: string]: any;
}) => (
  <select
    style={{
      width: "100%",
      height: 35,
      backgroundColor: "#F6F8FB",
      border: "0.415323px solid #E5E5E5",
    }}
    {...props}
  >
    {options.map((option) => (
      <option key={option.value} value={option.value}>
        {option.title}
      </option>
    ))}
  </select>
);
