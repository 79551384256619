import styled from "styled-components/macro";
import { useParams, useNavigate } from "react-router-dom";
import { BackButton } from "../../components/BackButton/BackButton";
import { ButtonLink } from "../../components/ButtonLink/ButtonLink";
import { HowToUse } from "../../components/HowToUse/HowToUse";
import { Title } from "../../components/Title/Title";
import { Spacer } from "../../components/Spacer/Spacer";
import { Demo } from "../types";
import { Link } from "react-router-dom";
import { Sidebar } from "../../components/Sidebar/Sidebar";
import { useDemos } from "../../hooks/use-demos";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #e5e5e5;
  padding: 44px;

  @media (min-width: 1200px) {
    flex-direction: row;
    padding-left: 0;
  }
`;

const SectionsWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 36px;

  @media (min-width: 1200px) {
    column-gap: 21px;
    flex-direction: row;
  }
`;

const InputOutputWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 25px;
  background-color: white;

  @media (min-width: 1200px) {
    max-width: 654px;
  }
`;

const SidebarWrapper = styled.div`
  @media (min-width: 1200px) {
    max-width: 330px;
  }
`;

const LinksWrapper = styled.div`
  display: flex;
  margin-bottom: 22px;
  justify-content: space-around;

  @media (min-width: 1200px) {
    justify-content: unset;
    column-gap: 13px;
  }
`;

const NavbarWrapper = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`;

const BackButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -73px;

  @media (min-width: 1200px) {
    display: block;
    margin-top: 0;
    padding-left: 267px;
  }
`;

export function BaseTemplate({
  demo,
  children,
}: {
  demo: Demo;
  children: any;
}) {
  const demos = useDemos();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <Wrapper>
      <NavbarWrapper>
        <Sidebar
          onClick={(section) => {
            if (section.external_url) {
              window.open(section.external_url, "_blank")
            } else {
              navigate(`/demos/${section.id}`);
            }
          }}
          sections={demos
            .filter((demo) => !demo.disabled)
            .map((demo) => ({
              id: demo.id,
              icon: demo.short_icon_type,
              selected: id === demo.id,
              label: demo.short_title,
              external_url: demo.external_url
            }))}
        />
      </NavbarWrapper>
      <BackButtonWrapper>
        <Link to="/">
          <BackButton />
        </Link>
      </BackButtonWrapper>
      <div style={{ width: "100%" }}>
        <Title style={{ lineHeight: "49px" }}>{demo.title}</Title>
        <Spacer height={35} />

        <SectionsWrapper>
          <InputOutputWrapper>{children}</InputOutputWrapper>

          <SidebarWrapper>
            <LinksWrapper>
              <ButtonLink
                as="a"
                href={demo.links.blog}
                icon="blog"
                label="Blog"
              />
              <ButtonLink
                as="a"
                href={demo.links.video}
                icon="video"
                label="Video"
              />
              <ButtonLink
                as="a"
                href={demo.links.repo}
                icon="repo"
                label="Repo"
              />
            </LinksWrapper>

            <HowToUse dangerouslySetInnerHTML={{ __html: demo.instructions }} />
          </SidebarWrapper>
        </SectionsWrapper>
      </div>
    </Wrapper>
  );
}
