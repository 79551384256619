import { useState } from "react";
import styled from "styled-components/macro";

import { ReactComponent as IconChart } from "./Icon-Chart.svg";
import { ReactComponent as IconGbp } from "./Icon-GBP.svg";
import { ReactComponent as IconNlp } from "./Icon-NLP.svg";
import { ReactComponent as IconTime } from "./Icon-Time.svg";
import { ReactComponent as CollapseButtonImage } from "./CollapseButtonClosed.svg";
import { BaseButton } from "../common/BaseButton/BaseButton";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 252px;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const SectionsWrapper = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  width: ${(props) => (props.isOpen ? 225 : 122)}px;
  /* transition: width 0.5s; */
  /* height: 386px; */

  background: #0e1e57;
  box-shadow: 10px 10px 40px rgba(14, 30, 87, 0.2);
  border-radius: 2px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */

  padding: 57px 0;
  gap: 8px;
`;

function Icon({ type }: { type: string }) {
  const IconComponent = {
    chart: IconChart,
    gbp: IconGbp,
    nlp: IconNlp,
    time: IconTime,
  }[type];

  return (
    // @ts-ignore
    <IconComponent width={30} height={30} />
  );
}

const SectionLabel = styled.span<{ isSelected: boolean }>`
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 12.7519px;
  line-height: 15px;

  color: ${(props) => (props.isSelected ? "#ff9f19" : "#fff")};
  margin-left: 24px;
`;

const Button = styled(BaseButton)`
  :hover {
    [stroke="white"] {
      stroke: #ff8e31;
    }

    [fill="white"] {
      fill: #ff8e31;
    }

    span {
      color: #ff8e31;
    }
  }
`;

const SectionWrapper = styled(Button)<{ isSelected: boolean }>`
  display: flex;
  align-items: center;

  ${(props) =>
    props.isSelected &&
    `span {
      color: #ff8e31;
    }

    [stroke="white"] {
      stroke: #ff8e31;
    }

    [fill="white"] {
      fill: #ff8e31;
    }`}
`;

const SelectedMarker = styled.span<{ isSelected: boolean }>`
  width: 5px;
  height: 42px;
  margin-right: 41px;

  background: ${(props) => (props.isSelected ? "#ff8e31" : "transparent")};
`;

function Section({
  icon,
  label,
  isSelected,
  onClick,
}: {
  icon: string;
  label?: string;
  isSelected: boolean;
  onClick: () => void;
}) {
  return (
    <SectionWrapper isSelected={isSelected} onClick={onClick}>
      <SelectedMarker isSelected={isSelected} />
      <Icon type={icon} />
      {label && <SectionLabel isSelected={isSelected}>{label}</SectionLabel>}
    </SectionWrapper>
  );
}

const CollapseButton = styled(Button)<{ isOpen: boolean }>`
  ${(props) =>
    props.isOpen &&
    `
    #arrow {
        transform: rotate(180deg);
        transform-origin: center;
    }
    `}
`;

interface TSection {
  id: string;
  icon: "nlp" | "chart" | "gbp" | "time";
  label: string;
  selected?: boolean;
  external_url?: string
}

export function Sidebar({
  sections,
  onClick,
}: {
  sections: Array<TSection>;
  onClick: (section: TSection) => void;
}) {
  const [isOpen, setIsOpen] = useState(true);

  return (
    <Wrapper>
      <SectionsWrapper isOpen={isOpen}>
        {sections.map((section, index) => (
          <Section
            key={index}
            icon={section.icon}
            {...(isOpen ? { label: section.label } : {})}
            isSelected={section.selected ?? false}
            onClick={() => onClick(section)}
          />
        ))}
      </SectionsWrapper>

      <CollapseButton isOpen={isOpen} aria-label="Collapse/Expand section">
        <CollapseButtonImage
          onClick={() => {
            setIsOpen((prevIsOpen) => !prevIsOpen);
          }}
        />
      </CollapseButton>
    </Wrapper>
  );
}
