import { useParams } from "react-router-dom";
import { useDemos } from "../../hooks/use-demos";
import { MediaTemplate } from "../../templates/MediaTemplate/MediaTemplate";
import { TextTemplate } from "../../templates/TextTemplate/TextTemplate";
import { Demo as TDemo } from "../../templates/types";

export function Demo() {
  const { id } = useParams();
  const demos = useDemos();

  const demo = demos.find((demo: TDemo) => demo.id === id);

  if (!demo) {
    return null;
  }

  const Template =
    demo.template_type === "media" ? MediaTemplate : TextTemplate;

  return <Template key={id} demo={demo} />;
}
