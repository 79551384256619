import styled from "styled-components/macro";
import { BaseButton } from "../common/BaseButton/BaseButton";

export const FormButton = styled(BaseButton)`
  background: #204ffb;
  box-shadow: 8.30645px 8.30645px 41.5323px rgba(0, 0, 0, 0.08);
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  /* or 15px */

  color: #ffffff;
  width: 127px;
  height: 35px;

  :disabled {
    background: #8da5fb;
  }
`;
