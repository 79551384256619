import { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { useDropzone } from "react-dropzone";
import { BaseTemplate } from "../BaseTemplate/BaseTemplate";
import { DragDropZone } from "../../components/DragDropZone/DragDropZone";
import { FormButton } from "../../components/FormButton/FormButton";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";
import { Demo } from "../types";
import { Spacer } from "../../components/Spacer/Spacer";
import { Progress } from "../../components/Progress/Progress";

const Wrapper = styled.div`
  background: white;
  padding: 22px 24px;
`;

const ButtonsWrapper = styled.div`
  margin-top: 19px;
  display: flex;
  column-gap: 9px;
`;

const OutputWrapper = styled.div`
  background: #fafafa;
  border: 0.415323px solid #e5e5e5;
  border-radius: 0.415323px;

  position: relative;
  overflow: hidden;

  box-sizing: border-box;
  /* width: 605px; */
  height: 439px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Media = styled.img`
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  max-height: calc(100% - 10px);
  object-fit: cover;
`;

const MediaLoadingText = styled.p`
  font-family: "Karla";
`;

function base64Encode(thing: any) {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };

    reader.onerror = () => {
      reject();
    };

    reader.readAsDataURL(thing);
  });
}

export function MediaTemplate({ demo }: { demo: Demo }) {
  const [input, setInput] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [output, setOutput] =
    useState<{ type: string; url: string } | null>(null);
  const [isOutputLoading, setIsOutputLoading] = useState(false);

  const [apiError, setApiError] = useState<{ statusCode: number } | null>(null);

  const { getRootProps, getInputProps, isDragActive, open, fileRejections } =
    useDropzone({
      maxFiles: 1,
      accept: demo.backend.validators.accepted_mimetypes?.join(","),
      noClick: true,
      onDrop([file]) {
        base64Encode(file).then(setInput);
      },
    });

  const fileError = fileRejections[0]?.errors[0]?.message;
  const error =
    fileError ||
    (apiError &&
      (Object.entries(demo.backend.error_messages).find(([statusCodeRegex]) =>
        String(apiError.statusCode).match(statusCodeRegex)
      )?.[1] ||
        demo.backend.error_messages.generic));

  useEffect(() => {
    if (fileError) {
      setInput(null);
      setApiError(null);
    }
  }, [fileError]);

  useEffect(() => {
    setIsOutputLoading(output !== null);
  }, [output]);

  const isSubmitDisabled = !input || Boolean(fileError) || isLoading;

  function handleSetExample() {
    fetch("/media-upload-example.png")
      .then((response) => response.blob())
      .then(base64Encode)
      .then(setInput)
      .catch(() => {
        setApiError({ statusCode: 0 });
      });
  }

  function handleSubmit() {
    setApiError(null);
    setOutput(null);
    setIsLoading(true);

    fetch(demo.backend.url, {
      method: "POST",
      headers: { "Access-Control-Allow-Origin": "*"},
      body: input,
    })
      .then((response) => {
        if (!response.ok) {
          setApiError({ statusCode: response.status });
          return;
        }

        return response.json();
      })
      .then((response) => {
        setOutput(response);
      })
      .catch(() => {
        setApiError({ statusCode: 0 });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function handleDownload() {
    const a = document.createElement("a");
    a.href = output!.url;
    a.download = "download";

    a.click();
    a.remove();
  }

  function getMediaProps(type: string) {
    return type === "img"
      ? {
          alt: "Model output",
        }
      : {
          controls: true,
        };
  }

  return (
    <BaseTemplate demo={demo}>
      <Wrapper>
        <div
          {...getRootProps({
            style: {
              position: "relative",
              overflow: "hidden",
            },
          })}
        >
          <input {...getInputProps()} />
          <DragDropZone active={isDragActive} />

          {input && (
            <Media
              {...(getMediaProps(
                input.startsWith("data:image/") ? "img" : "video"
              ) as any)}
              as={input.startsWith("data:image/") ? "img" : "video"}
              alt="Uploaded preview"
              src={input}
            />
          )}
        </div>

        <ButtonsWrapper>
          <FormButton onClick={open}>Upload</FormButton>
          <FormButton onClick={handleSetExample}>Example</FormButton>
          <FormButton disabled={isSubmitDisabled} onClick={handleSubmit}>
            Submit
          </FormButton>
        </ButtonsWrapper>

        <Spacer height={9} />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        {isLoading && <Progress expectedTime={demo.backend.timeout + 1000} />}

        {output && (
          <>
            <OutputWrapper>
              {isOutputLoading && (
                <MediaLoadingText>Loading result...</MediaLoadingText>
              )}
              <Media
                onLoad={() => {
                  setIsOutputLoading(false);
                }}
                onLoadedData={() => {
                  setIsOutputLoading(false);
                }}
                {...({
                  as: output.type,
                  ...getMediaProps(output.type),
                } as any)}
                src={output.url}
              />
            </OutputWrapper>

            {output.url.startsWith("data:") && (
              <div>
                <Spacer height={9} />
                <FormButton onClick={handleDownload}>Download</FormButton>
              </div>
            )}
          </>
        )}
      </Wrapper>
    </BaseTemplate>
  );
}
