import styled from "styled-components/macro";

export const Textarea = styled.textarea<{ error?: boolean }>`
  height: 226px;
  width: 100%;
  resize: none;

  background: #fafafa;
  border: 0.415323px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.415323px;

  ${(props) =>
    props.error &&
    `
  background: #FFF3F3;
  border: 0.415323px solid #F83333;
  `}
`;
