import styled from "styled-components/macro";
import { BaseButton } from "../common/BaseButton/BaseButton";
import { ReactComponent as IconBlog } from "./Icon-Blog.svg";
import { ReactComponent as IconRepo } from "./Icon-Repo.svg";
import { ReactComponent as IconVideo } from "./Icon-Video.svg";

function Icon({ type }: { type: string }) {
  const IconComponent = {
    blog: IconBlog,
    repo: IconRepo,
    video: IconVideo,
  }[type];

  return (
    // @ts-ignore
    <IconComponent />
  );
}

const IconWrapper = styled.div`
  width: 56px;
  height: 53px;

  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    background: #1f4df5;
    box-shadow: 4px 4px 10px rgba(31, 77, 245, 0.2);

    path:first-child {
      fill: white;
    }

    path:nth-child(n + 2) {
      fill: #1f4df5;
    }
  }
`;

const Label = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 150%;
  /* or 15px */
  text-align: center;
  color: #0e1e57;

  margin-top: 5px;
`;

export function ButtonLink({
  icon,
  label,
  ...props
}: {
  icon: "blog" | "video" | "repo";
  label: string;
  [key: string]: any;
}) {
  return (
    <BaseButton {...props}>
      <IconWrapper>
        <Icon type={icon} />
      </IconWrapper>

      <Label>{label}</Label>
    </BaseButton>
  );
}
