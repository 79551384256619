import { Fragment } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { Tile } from "../../components/Tile/Tile";
import { useDemos } from "../../hooks/use-demos";
import { Demo } from "../../templates/types";

const TilesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  justify-content: center;
  width: 100%;

  max-width: 1000px;
  margin: 0 auto;

  a {
    text-decoration: none;
  }
`;

export function Home() {
  const demos = useDemos() as any;

  return (
    <TilesWrapper>
      {demos.map((demo: Demo, index: number) => {
        const tile = (
          <Tile
            disabled={demo.disabled}
            title={demo.title}
            image={demo.title_image}
            description={demo.short_description}
          />
        );

        if (demo.disabled) {
          return <Fragment key={index}>{tile}</Fragment>;
        }

        return demo.external_url ? (
          <a key={index} target="_blank" rel="noreferrer" href={demo.external_url}>
            {tile}
          </a>
        ) : (
          <Link key={index} to={`/demos/${demo.id}`}>
            {tile}
          </Link>
        );
      })}
    </TilesWrapper>
  );
}
