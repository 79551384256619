import styled, { css } from "styled-components/macro";

const Wrapper = styled.div<{ image: string; disabled: boolean }>`
  display: block;
  border: none;

  padding: 0 50px;
  margin: 0;

  box-sizing: border-box;
  width: 319px;
  height: 323px;

  background-image: url(${(props) => props.image}),
    linear-gradient(180deg, #ffb1ee 0%, #1f4df5 100%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex-shrink: 0;

  ${(props) =>
    !props.disabled &&
    css`
      cursor: pointer;

      :hover {
        background: linear-gradient(
            rgba(32, 79, 251, 0.7),
            rgba(32, 79, 251, 0.7)
          ),
          url(${props.image});
      }
    `}
`;

const Title = styled.span`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;
  /* or 129% */

  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  color: #ffffff;

  margin: 0 0 50px 0;
`;

const Description = styled.span`
  margin: 0;
  padding: 0;

  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  /* or 133% */

  text-align: center;

  color: #ffffff;
`;

export function Tile({
  title,
  description,
  image,
  disabled = false,
}: {
  title: string;
  description?: string;
  image: string;
  disabled?: boolean;
}) {
  return (
    <Wrapper image={image} disabled={disabled}>
      <Title>{title}</Title>

      {description && <Description>{description}</Description>}
    </Wrapper>
  );
}
