import styled from "styled-components/macro";
import { Dropdown } from "../../components/Dropdown/Dropdown";
import { Spacer } from "../../components/Spacer/Spacer";
import { Demo } from "../types";
import { Textarea } from "../../components/Textarea/Textarea";
import { Output } from "../../components/Output/Output";
import React, { useState } from "react";
import { FormButton } from "../../components/FormButton/FormButton";
import { Progress } from "../../components/Progress/Progress";
import { ReactComponent as IconPopup } from "./Icon-Popup.svg";
import { BaseButton } from "../../components/common/BaseButton/BaseButton";
import { BaseTemplate } from "../BaseTemplate/BaseTemplate";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

const InputTextSummaryWrapper = styled.div`
  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  /* or 200% */

  color: #0e1e57;

  p {
    margin: 0;
  }

  span {
    color: #204ffb;
  }
`;

export function TextTemplate({ demo }: { demo: Demo }) {
  const defaultExtraOptions = demo.extra_options?.map(option => {
    const { option_title, option_values } = option
    return ({
      option_title: option_title,
      option_value: option_values[0].value
    })
  })
  const [extraOptions, setExtraOptions] = useState<any | null>(defaultExtraOptions)
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<{ statusCode: number } | null>(null);
  const [output, setOutput] = useState<string | null>(null);
  const [example, setExample] = useState("custom")

  const isInputValid =
    example !== "custom" ||
    (input.length > 0 &&
      (!demo.backend.validators.max_size ||
        input.length < demo.backend.validators.max_size) &&
      (!demo.backend.validators.min_words ||
        input.split(" ").length > demo.backend.validators.min_words)
    );
    
  const selectedExample = demo.examples?.find(({ title }) => title === example);


  const shownInput = selectedExample?.data || input;

  function upload() {
    setError(null);
    setIsLoading(true);
    const requestOptions: Record<string, string> = {};
    if (extraOptions) {
      for (let i = 0; i < extraOptions.length; i++) {
        const title: string = extraOptions[i].option_title
        const value = extraOptions[i].option_value
        requestOptions[title] = value
      }
    }


    fetch(demo.backend.url, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        instances: [shownInput],
        options: requestOptions
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setError({ statusCode: response.status });
          return;
        }

        return response.json();
      })
      .catch((error) => {
        setError({ statusCode: 0 });
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .then((response) => {
        setOutput(response.predictions[0]);
      });
  }

  return (
    <BaseTemplate demo={demo}>
      <div style={{ display: "flex" }}>
        <Dropdown
          options={[{ value: "custom", title: "Custom" }].concat(
            demo.examples.map((example) => ({
              value: example.title,
              title: example.title,
            }))
          )}
          value={example}
          onChange={(event: Event) => {
            // @ts-ignore
            setExample(event.target.value);
          }}
        />
        <BaseButton
          as="a"
          style={{
            backgroundColor: "#F6F8FB",
            width: 35,
            height: 35,
            border: "0.415323px solid #E5E5E5",
            borderLeft: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            cursor: example === "custom" ? "initial" : undefined,
          }}
          href={selectedExample?.url}
          {...(example === "custom"
            ? { disabled: true }
            : ({
              "aria-label": "View paper",
              title: "View paper",
              target: "_blank",
            } as any))}
        >
          <IconPopup />
        </BaseButton>
      </div>

      <Spacer height={9} />

      <Textarea
        placeholder="Input text"
        value={example !== "custom" && example ? selectedExample!.data : input}
        disabled={example !== "custom" && example ? true : false}
        onChange={(event) => {
          setInput(event.target.value);
        }}
        error={example === "custom" && Boolean(error)}
      />
      {
        demo.extra_options ?
          demo.extra_options.map((option, index) => {
            const { option_title, option_values } = option
            return (
              <React.Fragment key={index}>
                <h5 style={{ "fontFamily": "Karla" }}>{option_title}</h5>
                <Dropdown
                  options={option_values.map(option_value => ({
                    title: option_value.title,
                    value: option_value.value
                  }))}
                  value={extraOptions[index].option_value}
                  onChange={(event: Event) => {
                    const tempOptions = [...extraOptions]
                    tempOptions[index] = {
                      option_title: option_title,
                      // @ts-ignore
                      option_value: event.target.value
                    }
                    setExtraOptions(tempOptions)
                  }}
                />
              </React.Fragment>
            )
          })
          :
          null
      }

      <Spacer height={9} />

      <InputTextSummaryWrapper>
        <p>
          Input no. characters: <span>{shownInput.length}</span>
        </p>
        <p>
          Input no. words:{" "}
          <span>{shownInput ? shownInput.split(/\s+/).length : 0}</span>
        </p>
      </InputTextSummaryWrapper>

      <Spacer height={19} />

      <div style={{ display: "flex", columnGap: 12 }}>
        <FormButton disabled={!isInputValid || isLoading} onClick={upload}>
          SUBMIT
        </FormButton>

        {isLoading && <Progress expectedTime={demo.backend.timeout + 1000} />}
        {error && (
          <ErrorMessage>
            {Object.entries(demo.backend.error_messages).find(
              ([statusCodeRegex]) =>
                String(error.statusCode).match(statusCodeRegex)
            )?.[1] || demo.backend.error_messages.generic}
          </ErrorMessage>
        )}
      </div>

      <Spacer height={26} />

      <Output>{output}</Output>
    </BaseTemplate>
  );
}
