import styled from "styled-components/macro";
import { BaseButton } from "../common/BaseButton/BaseButton";
import { ReactComponent as IconArrow } from "./Icon-Arrow.svg";

const Wrapper = styled(BaseButton)`
  :not(:hover) {
    path {
      fill: #0e1e57;
    }

    circle {
      fill: white;
    }

    g {
      filter: none;
    }
  }
`;

export function BackButton() {
  return (
    <Wrapper>
      <IconArrow />
    </Wrapper>
  );
}
