import styled from "styled-components/macro";
import { ReactComponent as IconUpload } from "./Icon-Upload.svg";

const Wrapper = styled.div<{ active?: boolean }>`
  background: #fafafa;
  border: 0.415323px solid #e5e5e5;
  border-radius: 0.415323px;

  ${(props) => props.active && `border: 5px dashed #0e1e57;`}

  box-sizing: border-box;
  /* width: 605px; */
  height: 439px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.p`
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #0e1e57;

  margin: 25px 0 0 0;
  padding: 0;
`;

export function DragDropZone({ active }: { active?: boolean }) {
  return (
    <Wrapper active={active}>
      <IconUpload />

      <Text>Drag and Drop/Upload image or video</Text>
    </Wrapper>
  );
}
