import { useEffect, useState } from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 12px;
`;

const OuterCircle = styled.div`
  width: 26px;
  height: 26px;
  border-radius: 100%;

  --progress: 35%;
  background: conic-gradient(#204ffb var(--progress), #8da5fb var(--progress));
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerCircle = styled.div`
  width: 18px;
  height: 18px;
  background-color: white;
  border-radius: 100%;
`;

const Text = styled.p`
  margin: 0;
  padding: 0;

  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 20px;
  /* or 200% */

  color: #204ffb;
  font-variant-numeric: tabular-nums;
`;

export function Progress({ expectedTime = 15_000 }: { expectedTime?: number }) {
  const [progress, setProgress] = useState(0);

  function formatProgress() {
    // Prevent text shift when number of digits changes, e.g. 9->10, 99->100.
    const hiddenZeroes = (
      <span
        style={{
          display: "inline-block",
          width: 3 - String(progress).length + "ch",
        }}
      />
    );
    return (
      <>
        {hiddenZeroes}
        {progress}%
      </>
    );
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((progress) => {
        if (progress === 99) {
          clearInterval(intervalId);
        }

        return progress + 1;
      });
    }, expectedTime / 90);

    return () => {
      clearInterval(intervalId);
    };
  }, [expectedTime]);

  if (progress === 100) {
    return null;
  }

  return (
    <Wrapper>
      {/* @ts-ignore */}
      <OuterCircle style={{ "--progress": progress + "%" }}>
        <InnerCircle />
      </OuterCircle>

      <Text>{formatProgress()}... Loading</Text>
    </Wrapper>
  );
}
