import styled from "styled-components/macro";

export const HowToUse = styled.div`
  dl {
    background-color: white;
    padding: 27px 17px;
    margin: 0;
  }

  dt {
    font-family: "Karla";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;

    color: #0e1e57;

    margin-bottom: 22px;
  }

  dd {
    margin: 0;

    font-family: "Karla";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* or 200% */

    color: #0e1e57;
  }

  dd + dt {
    margin-top: 32px;
  }

  ol {
    margin: 0;
    padding: 0 0 0 16px;
  }

  li {
    font-family: "Karla";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* or 200% */

    color: #0e1e57;
  }
`;
