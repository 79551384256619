import styled from "styled-components/macro";

export const Output = styled.output`
  display: block;
  height: 226px;
  width: 100%;

  background: #fafafa;
  border: 0.415323px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 0.415323px;

  font-family: monospace;
`;
