import styled from "styled-components/macro";

export const ErrorMessage = styled.p`
  margin: 0;
  color: red;
  display: flex;
  align-self: center;

  font-family: "Karla";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
`;
