import styled from "styled-components/macro";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "@fontsource/karla/500.css";
import "@fontsource/karla/700.css";
import "@fontsource/playfair-display/700.css";
import { Home } from "./pages/Home/Home";
import { Demo } from "./pages/Demo/Demo";
import { DemoContext } from "./hooks/use-demos";
import "./index.css";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  background-image: url(/background.png);
  background-repeat: no-repeat;
  background-position-x: center;

  max-width: 1440px;
  margin: 0 auto;

  @media (min-width: 1200px) {
    background-size: contain;
  }
`;

const Header = styled.h1`
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 67px;
  /* identical to box height */

  text-align: center;
  color: #ffffff;

  margin: 0 0 70px 0;
  padding: 50px 0 0 0;
`;

function App() {
  const [apiResponse, setApiResponse] = useState(null);

  useEffect(() => {
    fetch(process.env.API_URL || "/mock-api-response.json")
      .then((response) => response.json())
      .then((response) => {
        setApiResponse(response);
      });
  }, []);

  if (!apiResponse) {
    return null;
  }

  return (
    <Wrapper>
      <Header><a style={{textDecoration: "none", color: "inherit"}} href="/">Datatonic Demo Hub</a></Header>
      <DemoContext.Provider value={apiResponse}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/demos/:id" element={<Demo />} />
          </Routes>
        </Router>
      </DemoContext.Provider>
    </Wrapper>
  );
}

export default App;
